import React, { Component } from "react";

class Blog extends Component {
  render() {
    return (
      <div id="coming-soon">
        <div className="section off-white">
          <h2 className="sectionTitle">
            <span>Coming in May 2019</span>
          </h2>
        </div>
      </div>
    );
  }
}

export default Blog;
